.v3-container {
  font-size: 14px;
  font-weight: normal !important;
  margin: 0;
  padding: 0;
}

.v3-container h1,
.v3-container h2,
.v3-container h3,
.v3-container h4,
.v3-container h5,
.v3-container h6 {
  margin: 0;
  padding: 0;
  line-height: 1.5rem;
}

.v3-container h1 {
  font-size: 20px;
}

.v3-container h2 {
  font-size: 18px;
}

.v3-container h3 {
  font-size: 16px;
}

.v3-container p {
  font-size: 14px;
  font-weight: normal !important;
  margin: 0;
  padding: 0;
}

.v3-container small {
  font-size: 12px;
}

/* Styles for Desktop */
@media (min-width: 900px) {
  .v3-container {
    font-size: 16px;
  }

  .v3-container h1 {
    font-size: 24px;
  }

  .v3-container h2 {
    font-size: 20px;
  }

  .v3-container h3 {
    font-size: 18px;
  }

  .v3-container p {
    font-size: 16px;
  }

  .v3-container small {
    font-size: 14px;
  }
}
